import React from 'react';
import './button.scss';


const Button = ({ isSubmit = "false", btnType, id, label, clickAction, additionalClass = "", isOutline = "false", link = "false", url, displayBtn, inline }) => {
    return (

        <div className={inline ? "btn-inline" : " btn-display"}>
            {
                link == "true" ?
                <a href={url} className={btnType == "primary" ? "button button-primary" : "button button-secondary"} target="_blank">{label}</a>
                :
                <button id={id} type={isSubmit == "true" ? "submit" : "button"} className={`button button-${btnType == "secondary" && isOutline == "true" ? "secondary-outline" : btnType} ${additionalClass}`} onClick={clickAction}>{label}</button>

            }
        </div>
    )
}

export default Button;