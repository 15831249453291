import React from "react"
import "./banner.scss"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../button/button"
import BackgroundImage from "gatsby-background-image"
import { HiQuestionMarkCircle } from "react-icons/hi";

const Banner = ({ props }) => {

    var bgFluidImage = { fluid: { srcWebp: props.bgImageWebp, src: props.bgImagePng } }

    return (
        <div uk-scrollspy="cls: uk-animation-fade; target: .banner-container; delay: 500; repeat: false">
            <div className="banner-container">
                {
                    props.margin == false || !props.margin ?
                        props.small == false ?
                            <div className="banner-container-default"
                            //style={{ background: props.bgColor ? props.bgColor : "#666666" }}
                            >
                                <div className={props.padding == true ? "default-banner banner-padding" : "default-banner"}
                                //style={{ background: props.bgColor ? props.bgColor : "#666666" }}
                                >
                                    {
                                        props.background ?
                                            <BackgroundImage Tag="section" role="img" className="background-zindex" fluid={bgFluidImage.fluid || ["rgba(0,0,0,0)"]} aria-label="header-image">
                                                <div className="banner-content uk-align-center padding-top padding-bottom" data-uk-grid style={{ background: props.bgColor ? props.bgColor : "#666666" }}>
                                                    <div className={props.inlineBtn ? "uk-align-center banner-elements-inline" : "uk-align-center banner-elements"}>
                                                        {props.image ? <img src={props.image} alt={""} className="banner-img"></img> : ""}
                                                        {props.title ?
                                                            props.h1 ? <h1>{props.title}</h1> : <h2>{props.title}</h2>
                                                            : ""
                                                        }
                                                        <b />
                                                        {props.subtitle ? <p>{props.subtitle}</p> : ""}
                                                        {
                                                            props.displayBtn ?
                                                                <Button
                                                                    link={props.link}
                                                                    label={props.label}
                                                                    url={props.url}
                                                                />
                                                                : ""
                                                        }
                                                        {
                                                            props.inlineBtn ?
                                                                props.inlineBtn.map((item, index) => {
                                                                    return (
                                                                        <Button
                                                                            inline="true"
                                                                            link={item.link}
                                                                            label={item.label}
                                                                            url={item.url} />
                                                                    )
                                                                }) : ""
                                                        }
                                                    </div>
                                                </div>
                                            </BackgroundImage>
                                            :
                                            <div className="banner-content uk-align-center padding-top padding-bottom" data-uk-grid>
                                                <div className={props.inlineBtn ? "uk-align-center banner-elements-inline" : "uk-align-center banner-elements"}>
                                                    {props.image ? <img src={props.image} alt={""} className="banner-img"></img> : ""}
                                                    {props.title ?
                                                        props.h1 ? <h1>{props.title}</h1> : <h2>{props.title}</h2>
                                                        : ""
                                                    }
                                                    {props.subtitle ? <p>{props.subtitle}</p> : ""}
                                                    {
                                                        props.displayBtn ?
                                                            <Button
                                                                link={props.link}
                                                                label={props.label}
                                                                url={props.url} />
                                                            : ""
                                                    }
                                                    {
                                                        props.inlineBtn ?
                                                            props.inlineBtn.map((item, index) => {
                                                                return (
                                                                    <Button
                                                                        inline="true"
                                                                        link={item.link}
                                                                        label={item.label}
                                                                        url={item.url} />
                                                                )
                                                            }) : ""
                                                    }
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            :
                            // div classname - padding
                            <div className="">
                                <div className="small-banner" style={{ backgroundColor: props.bgColor ? props.bgColor : "#f9f9f9" }}>
                                    <div className="banner-content uk-align-center padding-top padding-bottom" data-uk-grid>
                                        <div className="uk-align-center banner-elements">
                                            {props.title ? <h2>{props.title}</h2> : ""}
                                            {props.description ? <p className="des">{props.description}</p> : ""}
                                            {
                                                props.displayBtn ?
                                                    <Button
                                                        link={props.link}
                                                        label={props.label}
                                                        url={props.url}
                                                    />
                                                    : ""
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        <div className="margin-banner" style={props.bottomPadding ? { paddingBottom : "50px" } : {}}>
                            <div className="border" style={{ background: props.backgroundColor }}>
                                <div className="banner-content">
                                    {props.questionMark ? <HiQuestionMarkCircle className="question-mark" style={{ fontSize: "75px", color: props.themeColor, margin: "0 auto" }} /> : ""}

                                    <div className="info-box">
                                        {props.title ? <h4 className="banner-head">{props.title}</h4> : ""}
                                        {props.description ? <p className="banner-des">{props.description}</p> : ""}
                                        {
                                            props.info ? props.info.map((item, index) => {
                                                return (
                                                    <p className="banner-info">{item.text}</p>
                                                )
                                            }) : ""
                                        }
                                    </div>

                                    {
                                        props.displayBtn ?
                                            <div className="button-box">
                                                <a href={props.url} className="button" style={{ background: props.themeColor, border: `1px solid ${props.themeColor}` }} target="_blank">{props.label}</a>
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>

        </div>
    )

}

export default Banner